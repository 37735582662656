import React, { useEffect, useState, useRef } from 'react';
import { useElementSize } from 'use-element-size';

export const WaveUpSVG = ({ fill, opacity = '1', shadow }) => {
	const [height, setHeight] = useState(0);
	const ref = useRef(null);

	useEffect(() => {
		const browser = typeof window !== 'undefined';

		const onResize = () => {
			setHeight(ref.current?.clientHeight);
		};
		browser && window.addEventListener('resize', onResize);

		setHeight(ref.current?.clientHeight);
	});

	return (
		<svg
			role="presentation"
			style={{
				marginTop: `-${height}px`,
				position: 'relative',
				filter: shadow
					? 'drop-shadow(0px -20px 11px rgb(0 45 92 / 0.15))'
					: 'none',
			}}
			ref={ref}
			viewBox='0 0 2160 263'
			fill={fill}
			preserveAspectRatio='none'
			fillOpacity={opacity}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				id='Wave'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2160 262.5H0V0C360 80 720 120 1080 120C1440 120 1800 80 2160 0V262.5Z'
				fill={fill}
			/>
		</svg>
	);
};
